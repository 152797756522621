import React, { useState,useEffect } from "react";
import {  useSelector } from "react-redux";

import {
  Row,
  Stack,
  Column,
  Button,
  Img,
  Text,
 
} from "../../components";
import UserService from "../../services/user-service";
import { EyeInvisibleTwoTone,EyeTwoTone } from '@ant-design/icons';
import Sidebar from "../../components/Sidebar/Sidebar";
import ListAirtime from "../../components/ListAirtime";
// import { CloseSVG } from "../../assets/images/index.js";
import { Navigate,useNavigate } from "react-router-dom";

import Sidebarleft from "../../components/Sidebarleft";


const Chift = () => {
  const navigate = useNavigate();
  
  let calssSoled = true;
  const { user: currentUser } = useSelector((state) => state.auth);
 const { isLoggedIn } = useSelector(state => state.auth);
 const [showBalanceIcon, setShowBalanceIcon] = useState(false);
  const [balance, setBalance] = useState("");
  useEffect(() => {
   
  
    if(currentUser){
       
        UserService.getBalance(currentUser.response.user.id).then(
          (response) => {
            setBalance(response.data);
          },
          (error) => {
            // eslint-disable-next-line
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
    
              //setBalance(_content);
          }
        );
      }

    
   
  },
  // eslint-disable-next-line
   [])
  ;
 
   
  if(100000 >= parseInt(balance.solde) ){
    calssSoled = false
  }
  function handleBalanceIconClick() {
    setShowBalanceIcon(!showBalanceIcon);
  }
 
  function handleNavigateSubscribe() {
     navigate("/subscribe");
   
  }
  function handleNavigateCMU() {
    navigate("/cmu");
    
  }
  function handleNavigateChiftSrv() {
    navigate("/services-chift");
  
 }

  
 if(!isLoggedIn){
  return <Navigate replace to="/"/>

}else{

  return (
    <>
      <Row className="bg-bluegray_50 font-montserrat items-center mx-[auto] lg:pr-[21px] xl:pr-[27px] 2xl:pr-[30px] 3xl:pr-[36px] w-[100%]">
        <Sidebar  />
        <Stack className="3xl:h-[1050px] lg:h-[780px] xl:h-[900px] 2xl:h-[1040px] lg:ml-[20px] xl:ml-[26px] 2xl:ml-[29px] 3xl:ml-[35px] w-[80%]">
        
        <Sidebarleft  username={currentUser ? (currentUser.response.user.username):null} />

          <Column className="absolute justify-start left-[0] top-[0] w-[78%]">
            <Column className="bg-gray_50 font-inter items-center justify-end lg:p-[20px] xl:p-[26px] 2xl:p-[29px] 3xl:p-[35px] rounded-radius16 w-[100%]">
            {showBalanceIcon?
             (<Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
             <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
               <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                 Solde{" "}
               </span>
               <span  className={ calssSoled ? "text-bluegray_600 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
               ******** Fcfa
                 </span>
             </Text>
             <button
             onClick={handleBalanceIconClick}> 
               <EyeTwoTone twoToneColor="#674788" size="60px" /> 
             </button>
             {/* <Img
               src="images/img_ueyeslash.svg"
               className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
               alt="ueyeslash"
               onClick={handleBalanceIconClick}
             /> */}
           </Row>)
             :(
              <Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
              <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
                <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                  Solde{" "}
                </span>
                <span  className={ calssSoled ? "text-bluegray_600 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                   {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
              </Text>
              <button  onClick={handleBalanceIconClick}> <EyeInvisibleTwoTone  twoToneColor="#674788" size="50px" title=" Masquer Solde"/> </button>
              {/* <Img
                src="images/img_ueyeslash.svg"
                className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
                alt="ueyeslash"
                onClick={handleBalanceIconClick}
              /> */}
            </Row>
             )}
              
              
              <Column className="font-montserrat justify-start lg:mt-[23px] xl:mt-[29px] 2xl:mt-[30px] 3xl:mt-[40px] w-[99%] h-24 ">
                <Text
                  className="font-semibold text-black_900 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  CHIFT
                </Text>
                <Stack className="lg:h-[104px] xl:h-[130px] 2xl:h-[146px] 3xl:h-[175px] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] w-[100%]">
                 
                  <Column className="absolute bottom-[1%] items-center justify-start w-[100%]">
                    <Column className="justify-start w-[100%]">
                      <Row className="items-center justify-between w-[100%]">
                        <Row className="items-center justify-between w-[70%]">
                        
                          <Row className="btn common-pointer bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[26%]"
                            onClick={handleNavigateSubscribe}
                          >
                            <Button
                              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                              variant="icbFillWhiteA701"
                            >
                              <Img
                                src="images/img_add-member.png"
                                className="flex items-center justify-center lg:h-[25px] xl:h-[28px] 2xl:h-[28px] 3xl:h-[37px]"
                                alt="Group270"
                              />
                            </Button>
                            <Text
                              className="font-medium leading-[100.02%] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] text-black_900_cc w-[auto]"
                              as="h4"
                              variant="h4"
                            >
                              Souscrire
                              
                            </Text>
                          </Row>
                         
                          <Row  className="btn bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[28%]"

                           onClick={handleNavigateCMU}
                          >
                            <Button
                              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                              variant="icbFillWhiteA701"
                            >
                              <Img
                                src="images/img_cmu.png"
                                className="flex items-center justify-center"
                                alt="Group272"
                              />
                            </Button>
                            <Text className="font-medium leading-[100.02%] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] text-black_900_cc w-[30%]" as="h4" variant="h4">
                             CMU
                            </Text>
                          </Row>
                         
                          <Row className="btn bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[26%]"
                                  onClick={handleNavigateChiftSrv}>
                            <Button
                              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                              variant="icbFillWhiteA701"
                            >
                              <Img
                                src="images/img_chift.png"
                                className="flex items-center justify-center"
                                alt="Group272"
                              />
                            </Button>
                            <Text className="font-medium leading-[100.02%] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] text-black_900_cc w-[auto]" as="h4" variant="h4">
                              Services
                              <br /> CHIFT
                            </Text>
                          </Row>
                         
                        </Row>
                   
                      </Row>
                      
                    </Column>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Text className="columnhistoriquedes" as="h3" variant="h3">
              Historique des transactions
            </Text>
            <ListAirtime userId={currentUser ? (currentUser.response.user.id):null}/>
          </Column>
        </Stack>
      </Row>

     
    </>
  );

  }
};

export default Chift;
