import React, { useState } from "react";
import { Steps, Form, Select } from "antd";
import {
  Row,
  Stack,
  Button,
  Img,
  Text,
  Column,
  Line,
  Input,
} from "../../../components";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf("day");
};

const disabledRangeTime = (_, type) => {
  if (type === "start") {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
};

const { Step } = Steps;
const { Option } = Select;

const StepForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();

  const nextStep = () => {
    form.validateFields().then(() => {
      setCurrentStep(currentStep + 1);
    });
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinish = (values) => {
    // Handle the final form submission here
    console.log("Received values:", values);
  };

  const steps = [
    {
      title: "Informations personnelles",
      content: (
        <>
          <Form.Item
            name="name"
            label="Nom"
            rules={[{ required: true }]}
            as="h3"
          >
            <Input />
          </Form.Item>
          <Form.Item name="prenom" label="Prenom" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="adress" label="Adress" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Telephone"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cni"
            label="Numero CNI ou Passeport"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Button type="primary" onClick={nextStep}>
            Suivant
          </Button>
        </>
      ),
    },
    {
      title: "informations professionnelles",
      content: (
        <>
          <Form.Item
            name="country"
            label="Profession"
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="usa">Commerçant</Option>
              <Option value="uk">Artisan</Option>
              <Option value="pecheur">Pecheur</Option>
              <Option value="mecani">Mecanicien</Option>
              <Option value="eleveur">Eleveur</Option>
              <Option value="agriculteur">Agriculteur</Option>
              <Option value="restaurant">Restaurant</Option>
            </Select>
          </Form.Item>
          <Form.Item name="etude" label="Etudes" rules={[{ required: true }]}>
            <Select>
              <Option value="usa">Primaire</Option>
              <Option value="uk">Moyen</Option>
              <Option value="secondaire">Secondaire</Option>
              <Option value="superieur">Superieur</Option>
              <Option value="coranique">Coranique</Option>
              <Option value="no">Aucune etude</Option>
            </Select>
          </Form.Item>
          <Button style={{ marginRight: 8 }} onClick={prevStep}>
            Precedent
          </Button>
          <Button type="primary" onClick={nextStep}>
            Suivant
          </Button>
        </>
      ),
    },
    {
      title: "Services CHIFT",
      content: (
        <>
          <Form.Item
            name="jour"
            label="Jour de cotisation"
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="lundi">Lundi</Option>
              <Option value="Mardi">Mardi</Option>
              <Option value="Mercredi">Mercredi</Option>
              <Option value="Jeudi">Jeudi</Option>
              <Option value="Vendredi">Vendredi</Option>
              <Option value="Samedi">Samedi</Option>
              <Option value="Dimanche">Dimanche</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="evenement"
            label="Evenement Butoir"
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="Tabaski">Tabaski</Option>
              <Option value="Korite">Korite</Option>
              <Option value="Tamkharit">Tamkharit</Option>
              <Option value="Noel">Noel</Option>
              <Option value="Paques">Paques</Option>
              <Option value="Magal">Magal</Option>
              <Option value="Maouloud">Maouloud</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="mois"
            label="Evenement Butoir"
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="Tabaski">Tabaski</Option>
              <Option value="Korite">Korite</Option>
              <Option value="Tamkharit">Tamkharit</Option>
              <Option value="Noel">Noel</Option>
              <Option value="Paques">Paques</Option>
              <Option value="Magal">Magal</Option>
              <Option value="Maouloud">Maouloud</Option>
            </Select>
          </Form.Item>

          <Space direction="vertical" size={12}>
            <Text
              className="lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] text-black_900_cc w-[auto]"
              as="h4"
              variant="h4"
            >
              Date de debut et de fin
            </Text>
            <RangePicker
              disabledDate={disabledDate}
              disabledTime={disabledRangeTime}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  dayjs("00:00:00", "HH:mm:ss"),
                  dayjs("11:59:59", "HH:mm:ss"),
                ],
              }}
              format="YYYY-MM-DD HH:mm:ss"
            />
          </Space>
          <Line className="bg-black_900_19 h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[27px] 3xl:mt-[33px] w-[100%]" />

          <Button style={{ marginRight: 8 }} onClick={prevStep}>
            Precedent
          </Button>
          <Button type="primary" onClick={() => form.submit()}>
            Valider
          </Button>
        </>
      ),
    },
  ];

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="items-center justify-start lg:mb-[139px] xl:mb-[174px] 2xl:mb-[196px] 3xl:mb-[235px] w-[100%]"
    >
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentStep].content}</div>
    </Form>
  );
};

export default StepForm;
