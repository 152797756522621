import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Stack,
  Button,
  Img,
  Text,
  Column,
  Line,
  Input,
} from "./../../components";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Navigate, useNavigate } from "react-router-dom";
import SidebarMm from "../../components/SiderbarMm";
import Sidebarleft2 from "../../components/Sidebarleft2";
import { retrait } from "../../actions/om";
import UserService from "../../services/user-service";
import { clearMessage } from "../../actions/message";
import { OMpayencoursModal } from "./../../modals/OMpayencours";
import { OMpaymodalModal } from "./../../modals/OMpaymodal";
const OMretraitPage = () => {
  const navigate = useNavigate();
  const [isOpenModalhomeModal, setModalhomeModal] = React.useState(false);
  const [isOMpaymodalModal, setOMpaymodalModal] = React.useState(false);
  let calssSoled = true;
  const [error] = useState(null);
  const [error1, setError1] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [number, setNumber] = React.useState("");
  const [montant, setMontant] = React.useState("");
  const [successful, setSuccessful] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const { status } = useSelector((state) => state.status);
  const dispatch = useDispatch();
  const [balance, setBalance] = useState("");
  useEffect(
    () => {
      if (currentUser) {
        UserService.getBalance(currentUser.response.user.id).then(
          (response) => {
            setBalance(response.data);
          },
          (error) => {
            // eslint-disable-next-line
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();

            //setBalance(_content);
          }
        );
      }
    },
    // eslint-disable-next-line
    []
  );

  if (100000 >= parseInt(balance.solde)) {
    calssSoled = false;
  }

  function isValidNumber(setNumber) {
    return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})$/.test(setNumber);
  }
  const isEnabled = number.length > 8 && montant.length > 0;
  const onChangeNumber = (e) => {
    if (!isValidNumber(e.target.value)) {
      setError1("NUMERO INVALIDE");
    } else {
      setError1(null);
    }

    setNumber(e.target.value);
  };

  // function isValidSolde(montant) {
  //   if (montant >= balance.solde) return true;
  // }

  const onChangeMontant = (e) => {
    // if (isValidSolde(e.target.value)) {
    //   //setError('Le solde de votre compte ne vous permet pas deffectuer cette operation');
    //   setError(`Solde insuffisant. Votre solde est ${balance.solde} F`);
    // } else {
    //   setError(null);
    // }
    const m = e.target.value;
    setMontant(m);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentUser) {
      setModalhomeModal(true);
      setOMpaymodalModal(false);
      setSuccessful(true);

      dispatch(retrait(number, montant, currentUser.response.user.id))
        .then(() => {
          setSuccessful(false);

          //navigate("/om");
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  const showModal = () => {
    if (number === "" || montant === "") {
      return setOMpaymodalModal(false);
    }
    setOMpaymodalModal(true);
  };

  function handleCloseModalhomeModal() {
    setModalhomeModal(false);
    dispatch(clearMessage()); // clear message when changing location
    window.location.reload();
  }

  function handleCloseMpaymodalModal() {
    setOMpaymodalModal(false);
    dispatch(clearMessage()); // clear message when changing location
    window.location.reload();
  }

  function handleNavigate2() {
    navigate("/ompay");
  }
  function handleNavigate9() {
    navigate("/home");
  }
  function handleNavigate10() {
    navigate("/om");
  }
  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {
    return (
      <>
        <Row className="bg-bluegray_50 font-montserrat items-center mx-[auto] lg:pr-[21px] xl:pr-[27px] 2xl:pr-[30px] 3xl:pr-[36px] w-[100%]">
          <Sidebar />
          <Stack className="3xl:h-[1050px] lg:h-[780px] xl:h-[900px] 2xl:h-[1040px] lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] w-[80%]">
            <Text className="Transactionsr" as="h4" variant="h4">
              Transactions récentes
            </Text>
            <Stack className="absolute 3xl:h-[1050px] lg:h-[622px] xl:h-[778px] 2xl:h-[875px] w-[100%]">
              <Sidebarleft2
                userId={currentUser ? currentUser.response.user.id : null}
              />

              <Column className="absolute bg-gray_50 items-center justify-start left-[0] lg:py-[25px] xl:py-[32px] 2xl:py-[36px] 3xl:py-[43px] rounded-radius16 w-[78%]">
                <Column className="items-center justify-start lg:mb-[139px] xl:mb-[174px] 2xl:mb-[196px] 3xl:mb-[235px] w-[100%]">
                  <Row className="items-start justify-between w-[92%]">
                    <Row className="items-start justify-between w-[28%]">
                      <Button
                        className="common-pointer flex items-center justify-center lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] rounded-radius50 test_1200x630waTwo"
                        onClick={handleNavigate9}
                        variant="icbFillGray201"
                      >
                        <Img
                          src="images/img_arrowleft.svg"
                          className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                          alt="arrowleft"
                        />
                      </Button>
                      <Button
                        className="flex lg:h-[30px] xl:h-[37px] 2xl:h-[41px] 3xl:h-[50px] items-center justify-center lg:w-[29px] xl:w-[36px] 2xl:w-[40px] 3xl:w-[49px]"
                        variant="icbFillWhiteA701"
                      >
                        <Img
                          src="images/img_group266.png"
                          className="flex items-center justify-center lg:h-[20px] xl:h-[25px] 2xl:h-[28px] 3xl:h-[34px]"
                          alt="Group168"
                        />
                      </Button>
                      <Text className="rowarrowleft" as="h3" variant="h3">
                        Orange Money
                      </Text>
                    </Row>
                    <Row className="font-inter items-start justify-between mt-[1px] w-[36%]">
                      <Text
                        className="text-black_900 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                          Solde{" "}
                        </span>
                        <span
                          className={
                            calssSoled
                              ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"
                              : "text-amber_A700 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"
                          }
                        >
                          {new Intl.NumberFormat("de-DE").format(balance.solde)}{" "}
                          Fcfa
                        </span>
                      </Text>
                      {/* <Img
                      src="images/img_ueyeslash.svg"
                      className="ueyeslash_One"
                      alt="ueyeslash"
                    /> */}
                    </Row>
                  </Row>
                  <Line className="bg-black_900_19 h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[27px] 3xl:mt-[33px] w-[100%]" />
                  <Column className="justify-start lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[93%]">
                    <Text
                      className="text-black_900_cc w-[auto]"
                      as="h2"
                      variant="h2"
                    >
                      Type de transaction
                    </Text>
                    <Text className="Choisissezlat" as="h4" variant="h4">
                      Choisissez la transaction que vous vous voulez effectuer
                    </Text>
                    <Row className="items-center lg:mt-[11px] xl:mt-[14px] 2xl:mt-[16px] 3xl:mt-[19px] w-[71%]">
                      <Button
                        className="flex items-center justify-center text-center w-[30%]"
                        onClick={handleNavigate10}
                        leftIcon={
                          <Img
                            src="images/img_camera_3.svg"
                            className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                            alt="camera"
                          />
                        }
                        shape="RoundedBorder8"
                        size="md"
                        variant="OutlineBlack9007f"
                      >
                        <div className="common-pointer bg-transparent font-semibold lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                          Dépôt
                        </div>
                      </Button>
                      <Button
                        className="2xl:ml-[30px] 3xl:ml-[36px] flex items-center justify-center lg:ml-[21px] text-center w-[30%] xl:ml-[27px]"
                        leftIcon={
                          <Img
                            src="images/img_camera_4.svg"
                            className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                            alt="camera"
                          />
                        }
                        shape="RoundedBorder8"
                        size="md"
                        variant="OutlineBluegray600"
                      >
                        <div className="bg-transparent font-semibold lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                          Retrait
                        </div>
                      </Button>
                      <Button
                        className="2xl:ml-[30px] 3xl:ml-[36px] flex items-center justify-center lg:ml-[21px] text-center w-[30%] xl:ml-[27px]"
                        onClick={handleNavigate2}
                        leftIcon={
                          <Img
                            src="images/img_camera_2.svg"
                            className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                            alt="camera"
                          />
                        }
                        shape="RoundedBorder8"
                        size="md"
                        variant="OutlineBlack9007f"
                      >
                        <div className="common-pointer bg-transparent font-semibold lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                          Paiement
                        </div>
                      </Button>
                    </Row>
                    <Text className="Numrodubnf2" as="h3" variant="h3">
                      Numéro du bénéficiaire
                    </Text>
                    <Input
                      className="placeholder:text-black_900_b2 GroupFour"
                      wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                      name="GroupFour"
                      type="tel"
                      maxlength="9"
                      placeholder="7X XXX XX XX"
                      value={number}
                      onChange={onChangeNumber}
                    ></Input>
                    {error1 && <h2 style={{ color: "red" }}>{error1}</h2>}
                    <Text
                      className="lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] text-black_900_cc w-[auto]"
                      as="h3"
                      variant="h3"
                    >
                      Montant retiré{" "}
                    </Text>
                    <Input
                      className="placeholder:text-black_900_b2 GroupFour"
                      wrapClassName="2xl:mt-[8px] 3xl:mt-[10px] lg:mt-[6px] w-[100%] xl:mt-[7px]"
                      name="GroupThree"
                      type="number"
                      value={montant}
                      onChange={onChangeMontant}
                    ></Input>
                    {error && <h2 style={{ color: "red" }}>{error}</h2>}
                    <Button
                      className="common-pointer font-semibold lg:mt-[43px] xl:mt-[54px] 2xl:mt-[60px] 3xl:mt-[73px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                      onClick={showModal}
                      shape="RoundedBorder8"
                      size="md"
                      disabled={!isEnabled}
                    >
                      Valider la transaction
                    </Button>
                  </Column>
                </Column>
              </Column>
            </Stack>
            <SidebarMm
              username={currentUser ? currentUser.response.user.username : null}
            />
          </Stack>
        </Row>

        {isOpenModalhomeModal ? (
          <OMpayencoursModal
            isOpen={isOpenModalhomeModal}
            onRequestClose={handleCloseModalhomeModal}
            successful={successful}
            status={status}
            msg={`La transaction a été initiée`}
            message={message}
          />
        ) : null}

        {isOMpaymodalModal ? (
          <OMpaymodalModal
            isOpen={isOMpaymodalModal}
            onRequestClose={handleCloseMpaymodalModal}
            type={`Retrait OrangeMoney`}
            montant={montant}
            number={number}
            submit={handleSubmit}
          />
        ) : null}
      </>
    );
  }
};

export default OMretraitPage;
